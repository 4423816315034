import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import Layout from 'Layout/Layout';
import Loading from '_Shared/Loading/Loading';

const Home = lazy(() => import('Home/Home'));
const HowToReserveHome = lazy(() => import('HowToReserve/Home'));
const RarityHome = lazy(() => import('Rarity/Home'));
const LuckyDipHome = lazy(() => import('LuckyDip/Home'));
const SpecificHome = lazy(() => import('Specific/Home'));
const SwapHome = lazy(() => import('Swap/Home'));

function App() {
  return (
    <BrowserRouter>
      <Layout>
        <Suspense fallback={<Loading />}>
          <Route exact path='/' component={Home} />
          <Route exact path='/howToReserve' component={HowToReserveHome} />
          <Route exact path='/rarity' component={RarityHome} />
          <Route exact path='/luckyDip' component={LuckyDipHome} />
          <Route exact path='/specific' component={SpecificHome} />
          <Route exact path='/swap' component={SwapHome} />
        </Suspense>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
