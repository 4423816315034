import React from 'react';
import PropTypes from 'prop-types';
import '_Shared/_base.scss';
import logo from 'Layout/_images/logo.png';
import styles from 'Layout/layout.module.scss';
import Bar from 'Layout/components/Bar';

export default function Layout(props) {
  return (
    <div className={styles.container}>
      <Bar />
      <div className={styles.logo}>
        <img src={logo} alt='Company logo' className={styles.img} />
      </div>
      <div className={styles.content}>
        <main className={styles.main}>{props.children}</main>
      </div>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.any.isRequired,
};
