import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import DrawerComponent from "./Drawer";
import InternalLink from 'Home/components/InternalLink';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import '_Shared/_base.scss';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
    alignSelf: 'center'
  },
  toolbar: {
    display: 'flex',
    gap: '20px'
  },
  barColour: {
    backgroundColor: '#151515',
  },
}));

const menuItems = [
  {
    path: '/',
    title: 'Home'
  }, {
    path: '/howToReserve',
    title: 'How to reserve'
  },
  {
    path: '/rarity',
    title: 'Rarity'
  }, 
  {
    path: '/luckyDip',
    title: 'LuckyDip'
  },
  {
    path: '/specific',
    title: 'Reserve specific'
  }, 
  {
    path: '/swap',
    title: 'Swap'
  },
];

export default function Bar(props) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div className={classes.root}>
      <AppBar elevation={1} position="fixed" className={classes.barColour}>
        <Toolbar>
          {isMobile ? (
            <div className={classes.toolbar}>
              <DrawerComponent menuItems={menuItems} />
              <Typography variant="h6" className={classes.title}>
                Radthings
              </Typography>
            </div>
        ) : (
          <div className={classes.toolbar}>
            <Typography variant="h6" className={classes.title}>
              Radthings
            </Typography>
            {menuItems.map(item => {
                return <InternalLink path={item.path}>{item.title}</InternalLink>
              })}
          </div>
        )}
        </Toolbar>
      </AppBar>
    </div>
  );
}
