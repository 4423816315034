import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styles from '_Shared/link.module.scss';

export default function InternalLink(props) {
  const { path, children } = props;

  return (
    <Link to={path} className={styles.container}>
      {children}
    </Link>
  );
}

InternalLink.propTypes = {
  path: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
};
